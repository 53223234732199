<template>
  <CBox>
    <CBox bg="bgMain" h="100%" minHeight="100vh">
      <CBox
        pt="5px"
        pb="5px"
        maxW="1080px"
        mx="auto"
        :px="['0.7em', '1em', '1em', 'auto']"
      >
        <router-view />
      </CBox>
    </CBox>
  </CBox>
</template>

<script>
import { CBox } from "@chakra-ui/vue";

export default {
  components: {
    CBox,
  },
};
</script>
